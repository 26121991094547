import { getTenantTheme } from '../../theme/ComputedStyles/recoil/computedStyles.recoil';
import React, { Fragment, PropsWithChildren, useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { tenantSelector } from '../../state/tenantState';
import {
    adminPanelMenuItems,
    clientAppUserMenuItems,
    faqMenuItem,
} from '../../../../mock/userMenuItems';
import { Popover, Transition } from '@headlessui/react';
import Link from 'next/link';
import { IPopOverMenuItem } from '../popOverMenu/interfaces/popoverMenu.interfaces';
import { UserContextToggleCMSControls } from './elements/UserContextToggleCMSControls';
import { EAccess } from '../../../../interfaces/role/IAccess';
import { LanguageSelector } from '../../controls/LanguageSelector/LanguageSelector';
import { useLocalisationState } from '../../Translations/state/localisation.state';
import { ILocale } from '../../../../interfaces/localisation/locale.interfaces';
import { useUpdateUserLanguage } from '../../hooks/localisation/useUpdateUserLanguage';
import { toast } from 'react-toastify';
import { useTranslationApply } from '../../Translations/hooks/useTranslationApply';
import { useTranslation } from '../../Translations/hooks/useTranslation';
import { useAccess } from '../../access/hooks/useAccess';
import { useTenantFields } from '../../hooks/tenant/useTenantFields';

export interface IPopOverMenu extends PropsWithChildren {
    target?: string;
    isAdminPanel?: boolean;
    verticalMode?: 'up' | 'down';
    testId?: string;
    showFAQ?: boolean;
}

export const UserContextMenu: React.FC<IPopOverMenu> = (props) => {
    const { children, isAdminPanel, verticalMode, testId, showFAQ } = props;
    const tenantTheme = useRecoilValue(getTenantTheme);

    const tenant = useRecoilValue(tenantSelector);

    const { getTenantField } = useTenantFields();

    const { userLanguageOptions, userCurrentLanguage, tenantDefaultLanguage } =
        useLocalisationState();

    const { updateUserLanguage } = useUpdateUserLanguage();

    const defaultLanguage = useMemo(() => {
        return (
            userCurrentLanguage ||
            tenantDefaultLanguage || { languageCode: 'de-DE', default: false }
        );
    }, [tenantDefaultLanguage, userCurrentLanguage]);

    const { hasAccess } = useAccess();

    const showLanguageSelector: boolean = useMemo(() => {
        return userLanguageOptions && userLanguageOptions.length > 1;
    }, [userLanguageOptions]);

    const navItems = useMemo(() => {
        if (isAdminPanel === true) {
            return adminPanelMenuItems;
        }

        const items = [...clientAppUserMenuItems];

        if (showFAQ && getTenantField('showFAQInMenu')) {
            // Add to second position
            items.splice(items.length - 1, 0, faqMenuItem);
        }

        // Filter items by sidebarOnly
        return items.filter((item) => !item.sidebarOnly);
    }, [getTenantField, isAdminPanel, showFAQ]);

    const { applyTranslation } = useTranslationApply();
    const { getT } = useTranslation();

    const buttonPart = useCallback(
        (item: IPopOverMenuItem) => {
            return (
                <div className="relative flex h-full w-full items-center justify-center group">
                    <span
                        className="w-full px-7 py-2 font-semibold transition-all text-primary hover:text-primary-light group-hover:bg-neutral-200"
                        style={{
                            color: tenantTheme.colors.primary.DEFAULT,
                        }}
                        {...applyTranslation({
                            key: item.translationKey,
                        })}
                    >
                        {(item.translationKey && getT(item.translationKey)) ?? item.label}
                    </span>
                </div>
            );
        },
        [applyTranslation, getT, tenantTheme.colors.primary.DEFAULT]
    );

    const verticalStyles = useMemo(() => {
        return verticalMode === 'up' ? 'bottom-10' : 'top-10';
    }, [verticalMode]);

    const handleLanguageSelect = (locale: ILocale) => {
        // setSelectedLanguage(locale);
        void updateUserLanguage(locale.languageCode)
            .then(() => {
                // toast.success(
                //     getT('toastSuccessLanguageUpdated') ?? 'Sprache erfolgreich aktualisiert.',
                //     {
                //         toastId: 'general-language-update-success',
                //         autoClose: 2000,
                //     }
                // );
            })
            .catch(() => {
                toast.error(
                    getT('toastErrorLanguageNotUpdated') ??
                        'Sprache konnte nicht aktualisiert werden',
                    {
                        toastId: 'general-language-update-error',
                        autoClose: 2000,
                    }
                );
            });
    };

    return (
        <Popover className="relative" data-test-id={testId ?? 'popover-menu'}>
            {(popover) => (
                <>
                    <Popover.Button
                        className="relative flex w-full justify-center items-left"
                        data-test-id={testId ? `${testId}-popover` : 'popover-menu-button'}
                    >
                        {children}
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel
                            className={`absolute right-0 z-50 mt-2 ${verticalStyles} ${
                                !isAdminPanel ? 'w-[277px]' : 'w-[250px]'
                            }`}
                        >
                            <nav
                                className={`relative flex h-full w-full flex-col items-start justify-start gap-1 rounded border border-neutral-200 bg-neutral-50 pb-4 drop-shadow-md ${
                                    !isAdminPanel ? 'min-h-[100px] pt-3' : 'min-h-max py-3'
                                }`}
                            >
                                {navItems.map((item, index) => {
                                    // Prevent default Tenant to go into Client App
                                    if (
                                        tenant?.name.toLowerCase() === 'default' &&
                                        (item.id === 'admin-button-client-app' ||
                                            item.id === 'admin-button-profile')
                                    ) {
                                        return null;
                                    }

                                    // Access check
                                    if (item.access) {
                                        const accessGiven = hasAccess(item.access);

                                        if (!accessGiven) {
                                            return null;
                                        }
                                    }

                                    return (
                                        <div
                                            key={`popover-item-${item.id ?? ''}-${index}`}
                                            className="flex w-full"
                                        >
                                            {item.href !== undefined && (
                                                <Link
                                                    href={item.href ?? ''}
                                                    key={`nav-item-${index}`}
                                                    className="w-full font-medium visited:text-base hover:text-primary"
                                                    target={item.target ?? '_self'}
                                                    data-test-id={
                                                        item.id
                                                            ? `popover-item-${item.id}`
                                                            : undefined
                                                    }
                                                    onClick={() => popover.close()}
                                                >
                                                    {buttonPart(item)}
                                                </Link>
                                            )}
                                            {item.onClick !== undefined && (
                                                <button
                                                    onClick={() => {
                                                        if (item.onClick) {
                                                            item.onClick();
                                                            popover.close();
                                                        }
                                                    }}
                                                    key={`nav-item-${index}`}
                                                >
                                                    <span
                                                        className="font-medium visited:text-base hover:bg-red-500 hover:text-primary"
                                                        data-test-id={
                                                            item.id
                                                                ? `popover-item-${item.id}`
                                                                : undefined
                                                        }
                                                    >
                                                        {buttonPart(item)}
                                                    </span>
                                                </button>
                                            )}
                                        </div>
                                    );
                                })}
                                {/* Language Switcher Dropdown  */}

                                {showLanguageSelector && (
                                    <div className="relative my-auto mt-4 w-full pl-7">
                                        {/* TODO Need Adapter Refactoring */}
                                        <LanguageSelector
                                            currentLanguage={defaultLanguage}
                                            openToVertical={isAdminPanel ? 'up' : 'down'}
                                            openToSide={isAdminPanel ? 'center' : 'left'}
                                            onSelect={(value) => void handleLanguageSelect(value)}
                                            options={userLanguageOptions}
                                            selected={defaultLanguage}
                                        />
                                    </div>
                                )}

                                {!isAdminPanel && (
                                    <div className="relative flex w-full flex-col gap-1 p-4">
                                        {hasAccess([
                                            EAccess.mContent,
                                            EAccess.mLocalisation,
                                            EAccess.mCat,
                                        ]) && <UserContextToggleCMSControls />}
                                    </div>
                                )}
                            </nav>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
};

import { useRecoilValue } from 'recoil';
import { userRolesHaveAccess } from '../helper/userRolesHaveAccess';
import { userRolesIncludeAdmin } from '../helper/userRolesIncludeAdmin';
import { useCallback, useMemo } from 'react';
import { loggedInUserSelector } from '../../state/currentUser';
import { EAccess } from '../../../../interfaces/role/IAccess';

/**
 * Hook Adapter for checking if user has access to a specific feature.
 * hasAccess() will check if the user has access to one of the given access.
 */
export const useAccess = () => {
    const user = useRecoilValue(loggedInUserSelector);

    const roles = useMemo(() => {
        if (!user?.roles) {
            return [];
        }

        return user?.roles;
    }, [user?.roles]);

    const hasAnAdminRole = useCallback(() => {
        if (!user?.roles) {
            return false;
        }

        return userRolesIncludeAdmin({
            userRoles: user?.roles ?? [],
        });
    }, [user?.roles]);

    const hasAccess = useCallback(
        (oneOfAccess: EAccess[]): boolean => {
            if (!roles) {
                return false;
            }

            if (hasAnAdminRole()) {
                return true;
            }

            return userRolesHaveAccess({
                userRoles: roles ?? [],
                accessRequire: oneOfAccess,
                roleSchema: [...roles],
            });
        },
        [hasAnAdminRole, roles]
    );

    const hasAdminAreaAccess = useMemo(() => {
        return hasAccess([EAccess.gAdminArea]);
    }, [hasAccess]);

    return { hasAccess, hasAnAdminRole, hasAdminAreaAccess };
};

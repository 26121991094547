import { useEffect } from 'react';

export const useClickOutsideToClose = (
    ref: React.RefObject<HTMLElement>,
    onClose: () => void,
    open: boolean | null
) => {
    useEffect(() => {
        if (!open) return; // Only listen for clicks when the select is open

        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside); // Add event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside); // Return function to remove listener on component unmount
        };
    }, [onClose, open, ref]); // Only add listener when `open` changes
};

import React from 'react';
import { getCountryCodeFromLocale } from '../../Translations/helper/getCountryCodeFromLocale';

interface IFlagSymbol {
    countryCode: string;
    maxHeight?: number;
}

export const FlagSymbol: React.FC<IFlagSymbol> = (props) => {
    const { countryCode, maxHeight } = props;

    const normalizedCountryCode = getCountryCodeFromLocale(countryCode);

    return (
        <>
            {countryCode && (
                <img
                    className={`relative aspect-[4/3] h-full max-h-[24px] transition-opacity`}
                    src={`/assets/flags/4x3/${normalizedCountryCode}.svg`}
                    alt={normalizedCountryCode ?? 'gb'}
                    style={{ maxHeight }}
                />
            )}
        </>
    );
};

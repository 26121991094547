import { useApi } from '../../../../general/hooks/api/useApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { IUser } from '../../../../../interfaces/IUser';

export interface ISetFieldValueInput {
    fieldName: string;
    fieldValue: string;
    visible?: boolean;
}

export const useUserSetFieldValueMutation = () => {
    const { postGql } = useApi();

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (input: ISetFieldValueInput) => {
            const { userFieldSetValue: data } = await postGql<{ userFieldSetValue: IUser }>(
                'userFieldSetValue',
                gql`
                    mutation ($input: FieldSetValueInput!) {
                        userFieldSetValue(input: $input) {
                            id
                            creation
                            name
                            email
                            archived
                            fields {
                                name
                                value
                            }
                        }
                    }
                `,
                { input }
            );

            return data;
        },
        onSuccess: () => {
            const key = ['whoAmI'];
            void queryClient.invalidateQueries({ queryKey: key });
        },
    });
};

import React, { useMemo } from 'react';
import { Toggle } from '../../../controls/toggle/Toggle';

export interface IUserContextToggle {
    set: (checked: boolean) => void;
    checked: boolean;
    label: string;
    labelSmall: string;
    description?: string;
}

export const UserContextToggle: React.FC<IUserContextToggle> = (props) => {
    const { set, checked, labelSmall, label, description } = props;

    const bgColor = useMemo(() => {
        return checked ? 'bg-cloudbarPrimary-light/20' : 'bg-neutral-100';
    }, [checked]);

    return (
        <div
            className={`relative flex flex-col gap-1 rounded border-2 px-2 transition-all hover:border-cloudbarPrimary ${bgColor}`}
        >
            <div className={`relative flex w-full flex-row gap-4`}>
                <label className="flex h-12 grow items-center text-sm md:hidden">
                    {labelSmall}
                </label>
                <label className="hidden h-12 grow items-center text-sm md:flex">{label}</label>
                <Toggle
                    className="w-min"
                    color="default"
                    onChange={(checkedLocal) => {
                        set(checkedLocal);
                    }}
                    checked={checked}
                />
            </div>
            {description && (
                <span className="p-1 pt-0 text-xs text-neutral-500 max-w-56">{description}</span>
            )}
        </div>
    );
};

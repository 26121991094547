import { IPopOverMenuItem } from '../components/general/elements/popOverMenu/interfaces/popoverMenu.interfaces';
import { EAccess } from '../interfaces/role/IAccess';

export const clientAppUserMenuItems: IPopOverMenuItem[] = [
    {
        id: 'user-button-client-home',
        label: 'Startseite',
        translationKey: 'navigationHome',
        href: '/',
        sidebarOnly: true,
    },
    {
        id: 'user-button-client-profile',
        label: 'Mein Konto',
        translationKey: 'navigationProfile',
        href: '/profile',
    },
    // {
    //     id: 'user-button-client-credentials',
    //     label: 'Zugangsdaten verwalten',
    //     translationKey: 'navigationCredentials',
    //     href: '/profile',
    // },
    {
        id: 'user-button-client-order-history',
        label: 'Bestellhistorie',
        translationKey: 'navigationHistory',
        href: '/cart?section=history',
    },
    {
        id: 'user-button-client-order-for-later',
        label: 'Merkliste',
        translationKey: 'navigationForLater',
        href: '/cart?section=saved',
    },
    {
        id: 'user-button-client-admin',
        label: 'Admin Panel',
        translationKey: 'navigationAdminPanel',
        href: '/admin/overview',
        access: [EAccess.gAdminArea],
    },
    {
        id: 'user-button-client-logout',
        label: 'Ausloggen',
        translationKey: 'navigationLogout',
        href: '/login?logout=1',
    },
];

export const faqMenuItem: IPopOverMenuItem = {
    id: 'faq',
    label: 'FAQ',
    translationKey: 'navigationFAQ',
    href: '/page?slug=faq',
};

export const adminPanelMenuItems: IPopOverMenuItem[] = [
    {
        id: 'admin-button-client-app',
        label: 'Client App',
        translationKey: 'navigationClientApp',
        href: '/',
    },
    {
        id: 'admin-button-profile',
        label: 'Profil',
        translationKey: 'navigationProfileFromAdmin',
        href: '/profile',
        target: 'blank',
    },
    {
        id: 'admin-button-logout',
        translationKey: 'navigationLogout',
        label: 'Ausloggen',
        href: '/login?logout=1',
    },
];

export const getCountryCodeFromLocale = (locale: string): string => {
    if (!locale) return 'gb'; // Default fallback

    // Handle hyphenated formats like "en-GB"
    if (locale.includes('-')) {
        return locale.split('-')[1]?.toLowerCase() || 'gb';
    }

    // Handle languages that match country codes directly
    if (['de', 'fr', 'es', 'it', 'nl', 'pt', 'ru'].includes(locale.toLowerCase())) {
        return locale.toLowerCase();
    }

    // Special cases
    const specialCases: { [key: string]: string } = {
        en: 'gb',
        zh: 'cn',
        ja: 'jp',
        ko: 'kr',
        ar: 'sa',
    };

    return specialCases[locale.toLowerCase()] || locale.toLowerCase();
};

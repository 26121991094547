import { IRole } from '../../../../interfaces/role/IRole';
import { EAccess } from '../../../../interfaces/role/IAccess';

type TUserRolesHaveAccess = {
    userRoles: IRole[];
    accessRequire: EAccess[];
    roleSchema: IRole[];
};

/**
 * Check if user roles have access to the required access.
 * Will go through all user roles and check if the role has the required access.
 * @param userRoles
 * @param accessRequire
 * @param roleSchema
 * @returns
 * @param props
 */
export const userRolesHaveAccess = (props: TUserRolesHaveAccess): boolean => {
    const { userRoles, accessRequire, roleSchema } = props;

    for (const role of userRoles) {
        // Admin Bailout
        if (role.admin === true) {
            return true;
        }

        const roleSchemaFind = roleSchema.find((r) => r.id === role.id);

        if (roleSchemaFind) {
            for (const access of accessRequire) {
                if (roleSchemaFind?.access?.includes(access)) {
                    return true;
                }
            }
        }
    }

    return false;
};

import React, { Fragment, PropsWithChildren, useCallback, useMemo } from 'react';
import { Popover, Transition } from '@headlessui/react';
import Link from 'next/link';
import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../theme/ComputedStyles/recoil/computedStyles.recoil';
import { IPopOverMenuItem } from './interfaces/popoverMenu.interfaces';

export interface IPopoverMenu extends PropsWithChildren {
    testId?: string;
    verticalStyles?: string;
    items: IPopOverMenuItem[];
    openToSide?: 'right' | 'left' | 'center';
    openToVertical?: 'up' | 'down';
    onChange?: (open: boolean) => void;
}

export const PopoverMenu: React.FC<IPopoverMenu> = (props) => {
    const {
        children,
        testId,
        verticalStyles,
        items,
        openToSide,
        onChange,
        openToVertical = 'down',
    } = props;

    const tenantTheme = useRecoilValue(getTenantTheme);

    const buttonPart = useCallback(
        (item: IPopOverMenuItem) => {
            const ItemChip = item.chip ? item.chip() : null;
            return (
                <div className="relative flex h-full w-full items-center justify-center group">
                    {item.icon && (
                        <div className="flex h-10 w-10 items-center justify-center p-2 aspect-[1/1]">
                            {item.icon}
                        </div>
                    )}

                    {ItemChip ? <div className="w-1/5">{ItemChip}</div> : null}

                    <span
                        className="w-full px-2 py-2 pl-2 text-left font-semibold transition-all text-primary hover:text-primary-light group-hover:bg-neutral-100"
                        style={{
                            color: tenantTheme.colors.primary.DEFAULT,
                        }}
                    >
                        {item.label}
                    </span>
                </div>
            );
        },
        [tenantTheme.colors.primary.DEFAULT]
    );

    const sideStyles = useMemo(() => {
        switch (openToSide) {
            case 'left':
                return 'right-0';
            case 'right':
                return 'left-0';
            case 'center':
                return 'left-1/2 -translate-x-1/2';
            default:
                return 'left-0';
        }
    }, [openToSide]);

    const verticalDirectionStyles: string = useMemo(() => {
        if (openToVertical === 'up') {
            return 'bottom-10';
        }

        return '';
    }, [openToVertical]);

    return (
        <Popover className="relative" data-test-id={testId ?? 'popover-menu'}>
            {(popover) => {
                if (onChange) {
                    onChange(popover.open);
                }

                return (
                    <>
                        <Popover.Button
                            className="relative flex w-full justify-center items-left"
                            data-test-id={
                                testId ? `${testId}-popover-menu-button` : 'popover-menu-button'
                            }
                        >
                            {children}
                        </Popover.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel
                                className={`absolute z-10 mt-2 ${verticalDirectionStyles} ${sideStyles} ${
                                    verticalStyles ?? ''
                                } h-max w-[250px]`}
                            >
                                <nav
                                    className={`relative flex min-h-max w-full flex-col items-start justify-start gap-1 rounded border border-neutral-200 bg-neutral-50 py-3 pb-4 drop-shadow-md`}
                                    data-test-id={`${testId ?? ''}-panel`}
                                >
                                    {items.map((item, index) => {
                                        return (
                                            <div
                                                key={`popover-item-${item.id ?? ''}-${index}`}
                                                className="flex w-full"
                                            >
                                                {item.href !== undefined && (
                                                    <Link
                                                        href={item.href ?? ''}
                                                        key={`nav-item-${index}`}
                                                        className="w-full font-medium visited:text-base hover:text-primary"
                                                        target={item.target ?? '_self'}
                                                        data-test-id={
                                                            item.testId ??
                                                            (item.id
                                                                ? `popover-item-${item.id}`
                                                                : undefined)
                                                        }
                                                        onClick={() => popover.close()}
                                                    >
                                                        {buttonPart(item)}
                                                    </Link>
                                                )}
                                                {item.onClick !== undefined && (
                                                    <button
                                                        className="w-full"
                                                        onClick={() => {
                                                            if (item.onClick) {
                                                                item.onClick();
                                                                popover.close();
                                                            }
                                                        }}
                                                        key={`nav-item-${index}`}
                                                    >
                                                        <span
                                                            className="w-full font-medium visited:text-base hover:text-primary"
                                                            data-test-id={
                                                                item.id
                                                                    ? `popover-item-${item.id}`
                                                                    : undefined
                                                            }
                                                        >
                                                            {buttonPart(item)}
                                                        </span>
                                                    </button>
                                                )}
                                            </div>
                                        );
                                    })}
                                </nav>
                            </Popover.Panel>
                        </Transition>
                    </>
                );
            }}
        </Popover>
    );
};

import { useRecoilValue } from 'recoil';
import { tenantSelector } from '../../state/tenantState';
import { useCallback } from 'react';

export const useTenantFields = () => {
    const tenant = useRecoilValue(tenantSelector);

    const getTenantField = useCallback(
        (fieldName: string) => {
            const fields = tenant?.fields;

            if (fields) {
                return fields[fieldName];
            }
        },
        [tenant?.fields]
    );

    return { getTenantField };
};

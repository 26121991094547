import React, { useMemo } from 'react';
import Link from 'next/link';
import { imagePathSanitizer } from '../../../../helper/imagePathSanitizer';

interface ICompanyHeaderLogoOnly {
    src?: string;
    blockClick?: boolean;
    height?: string;
}

// Dimensions:
// Original:                900px x 186px  Ratio: 4.84 (4.84:1)
// Header (Scaled down):    300px x 62px Ratio: 4.84 (4.84:1)
export const CompanyHeaderLogoOnly: React.FC<ICompanyHeaderLogoOnly> = (props) => {
    const { src, blockClick, height = '62px' } = props;

    const imagePath = useMemo(() => {
        if (!src) return null;

        return imagePathSanitizer(src);
    }, [src]);

    return (
        <>
            {imagePath && (
                <Link
                    href={blockClick ? '' : '/'}
                    className={`${blockClick ? 'pointer-events-none' : ''} flex w-min`}
                    style={{
                        width: 'max-content',
                    }}
                >
                    <div className="flex flex-col items-start justify-start">
                        <img
                            src={imagePath}
                            alt="Logo"
                            className="h-full object-contain"
                            data-test-id="logo-wide"
                            style={{
                                // width: '300px',
                                height: height,
                            }}
                        />
                    </div>
                </Link>
            )}
        </>
    );
};

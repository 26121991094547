import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Switch } from '@headlessui/react';

import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../theme/ComputedStyles/recoil/computedStyles.recoil';
import { Typography } from '../../typo/Typography/Typography';
import { ArrowPathIcon } from '@heroicons/react/16/solid';
import { useTranslationApply } from '../../Translations/hooks/useTranslationApply';
import { useTranslation } from '../../Translations/hooks/useTranslation';

export interface IToggle {
    checked?: boolean;
    onChange: (checked: boolean) => void;
    testId?: string;
    isRequired?: boolean;
    overwriteActiveColor?: string;
    color?: 'default' | 'primary' | 'disabled' | 'success' | 'danger';
    disabled?: boolean;
    label?: React.ReactNode;
    labelTranslationKey?: string;
    labelSmall?: React.ReactNode;
    labelSmallTranslationKey?: string;
    className?: string;
    isLoading?: boolean;
    showValidation?: boolean;
}

export const Toggle: React.FC<IToggle> = (props) => {
    const {
        onChange,
        checked = false,
        testId,
        isRequired,
        overwriteActiveColor,
        color,
        disabled,
        label,
        labelTranslationKey,
        labelSmall,
        labelSmallTranslationKey,
        className,
        isLoading,
        showValidation,
    } = props;

    const [checkedState, setCheckedState] = useState(checked);
    const tenantTheme = useRecoilValue(getTenantTheme);

    const { applyTranslation } = useTranslationApply();
    const { getT } = useTranslation();

    const toggle = useCallback(() => {
        onChange(!checkedState);
        setCheckedState(!checkedState);
    }, [checkedState, onChange]);

    useEffect(() => {
        setCheckedState(checked);
    }, [checked]);

    const useColor = useMemo(() => {
        if (disabled) {
            return 'bg-background-disabled';
        }

        switch (color) {
            case 'primary':
                return 'bg-background-primary';

            case 'disabled':
                return 'bg-background-disabled';

            case 'success':
                return 'bg-background-success';

            case 'danger':
                return 'bg-background-danger';
        }
    }, [color, disabled]);

    const useTenantThemeColor = useMemo(() => {
        if (disabled) {
            return tenantTheme.colors.invert.disabled;
        }

        switch (color) {
            case 'primary':
                return tenantTheme.colors.primary.DEFAULT;
            case 'disabled':
                return tenantTheme.colors.invert.disabled;
            case 'success':
                return tenantTheme.colors.success.DEFAULT;
            case 'danger':
                return tenantTheme.colors.danger.DEFAULT;
            default:
                return tenantTheme.colors.primary.DEFAULT;
        }
    }, [
        color,
        disabled,
        tenantTheme.colors.danger.DEFAULT,
        tenantTheme.colors.invert.disabled,
        tenantTheme.colors.primary.DEFAULT,
        tenantTheme.colors.success.DEFAULT,
    ]);

    const currentColor = useMemo(() => {
        if (checkedState && !overwriteActiveColor) {
            return useColor;
        } else if (!checkedState && overwriteActiveColor) return overwriteActiveColor;

        if (checkedState && color === 'success') {
            return 'bg-background-success';
        }

        if (checkedState && !overwriteActiveColor && isRequired) {
            return 'bg-background-danger';
        } else return 'bg-background-dark';
    }, [isRequired, checkedState, overwriteActiveColor, color, useColor]);

    const currentTenantThemeColor = useMemo(() => {
        if (showValidation && isRequired && !checkedState) {
            return tenantTheme.colors.background.danger;
        }

        if (checkedState && !overwriteActiveColor) {
            return useTenantThemeColor;
        } else if (!checkedState && overwriteActiveColor) return overwriteActiveColor;

        if (checkedState && color === 'success') {
            return tenantTheme.colors.background.success;
        }

        if (checkedState && !overwriteActiveColor && isRequired) {
            return tenantTheme.colors.background.danger;
        } else return tenantTheme.colors.background.dark;
    }, [
        showValidation,
        isRequired,
        checkedState,
        overwriteActiveColor,
        color,
        tenantTheme.colors.background.dark,
        tenantTheme.colors.background.danger,
        tenantTheme.colors.background.success,
        useTenantThemeColor,
    ]);

    return (
        <div
            className={`inline-flex flex-col items-center gap-0 lg:flex-row ${className ?? ''}`}
            data-test-id={testId ?? undefined}
        >
            <Switch
                checked={checkedState ?? false}
                onChange={toggle}
                data-test-id={`${testId ?? ''}-switch`}
                data-test-checked={checkedState ? 'true' : 'false'}
                id={`${testId ?? ''}`}
                disabled={disabled}
                className={`${
                    currentColor ?? ''
                } relative inline-flex h-[38px] w-[74px] flex-shrink-0 scale-75 ${
                    disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                }  rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                style={currentTenantThemeColor ? { backgroundColor: currentTenantThemeColor } : {}}
            >
                <span
                    aria-hidden="true"
                    data-test-id={`${testId ?? ''}-roundIcon`}
                    className={`${checkedState ? 'translate-x-9' : 'translate-x-0'} ${
                        color === 'disabled' ? 'bg-invert-disabled' : 'bg-white'
                    } pointer-events-none inline-block h-[34px] w-[34px] transform items-center justify-center rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
                >
                    {isLoading && <ArrowPathIcon className="h-3 text-primary" />}
                </span>
            </Switch>

            {label && (
                <span className="hidden lg:flex">
                    <Typography variant="label">
                        <span
                            {...applyTranslation({
                                key: labelTranslationKey,
                            })}
                        >
                            {getT(labelTranslationKey ?? '') ?? label}
                        </span>
                    </Typography>
                </span>
            )}

            {labelSmall && (
                <span className="flex lg:hidden">
                    <Typography variant="label">
                        <span
                            {...applyTranslation({
                                key: labelSmallTranslationKey,
                            })}
                        >
                            {getT(labelSmallTranslationKey ?? '') ?? labelSmall}
                        </span>
                    </Typography>
                </span>
            )}
        </div>
    );
};

import { IRole } from '../../../../interfaces/role/IRole';

type TUserRolesHaveAccess = {
    userRoles: IRole[];
};

export const userRolesIncludeAdmin = (props: TUserRolesHaveAccess): boolean => {
    const { userRoles } = props;

    for (const role of userRoles) {
        if (role.admin === true) {
            return true;
        }
    }

    return false;
};

import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { Label } from '../../typo/label/Label';
import { ValidationMessage } from '../../typo/validationMessage/ValidationMessage';
import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../theme/ComputedStyles/recoil/computedStyles.recoil';
import { ChevronUpIcon, XMarkIcon } from '@heroicons/react/16/solid';
import { useClickOutsideToClose } from '../../hooks/ClickOutToCloseHandler/useClickOutsideToClose';
import { useTranslationApply } from '../../Translations/hooks/useTranslationApply';
import { ETranslationEntity } from '../../Translations/types/translationApply.types';

export interface ISelectOption {
    name: string;
    id: string;
    icon?: ReactElement<unknown>;
    prefix?: string;
}

export interface ISelect {
    id?: string;
    testId?: string;
    required?: boolean;
    direction?: 'up' | 'down';
    label?: string;
    labelTag?: ReactElement<unknown>;
    labelDescription?: string;
    placeholder?: string;
    placeholderTranslationKey?: string;
    autocomplete?: string;
    defaultValue?: string;
    disabled?: boolean;
    onChange?: (id: string) => void;
    onBlur?: (id: string) => void;
    manipulation?: (value: string) => string;
    iconStart?: ReactElement<unknown>;
    iconStartOpen?: ReactElement<unknown>;
    iconSpin?: boolean;
    validation?: 'email' | 'url' | 'number' | 'phone' | 'custom' | 'password' | 'mobile';
    validationRegEx?: RegExp;
    validationMessage?: string;
    showValidation?: boolean;
    reportValidation?: (id: string, isValid: boolean) => void;
    alignWithFormFields?: boolean;
    options?: ISelectOption[];
    preSelected?: string;
    searchable?: boolean;
    clearable?: boolean;
    labelType?: 'name' | 'id';
    labelTranslationKey?: string;
    selectTranslationKeys?: string[];
    showFillMe?: boolean;
}

interface IValidation {
    isValid: boolean;
    message: string;
}

export const Select: React.FC<ISelect> = (props) => {
    const {
        id = '',
        testId,
        required = false,
        label,
        labelTag,
        placeholder,
        autocomplete,
        defaultValue,
        onChange,
        onBlur,
        iconStart,
        iconStartOpen,
        validationMessage,
        reportValidation,
        showValidation = false,
        disabled,
        alignWithFormFields,
        options,
        preSelected,
        direction = 'down',
        searchable = false,
        clearable = false,
        labelType = 'name',
        labelTranslationKey,
        selectTranslationKeys,
        iconSpin = false,
        showFillMe = false,
    } = props;

    // sanitize preSelection
    const isPreSelectedValid = () => {
        if (!preSelected) return false;

        const matches = options?.filter(
            (option) =>
                option.id.toLowerCase() === preSelected.toLowerCase() ||
                option.name?.toLowerCase() === preSelected.toLowerCase()
        );

        return !(!matches || matches.length === 0);
    };

    const [value, setValue] = useState<string | null>(null);
    const [open, setOpen] = useState<boolean | null>(false);
    const [selected, setSelected] = useState<string | null | undefined>(
        isPreSelectedValid() ? preSelected : null
    );

    const wrapperRef = useRef<HTMLDivElement | null>(null); // Create a reference for the dropdown

    useClickOutsideToClose(
        wrapperRef,
        () => {
            setOpen(false);
        },
        open
    );

    const { applyTranslation } = useTranslationApply();

    const tenantTheme = useRecoilValue(getTenantTheme);

    const [isInitialized, setIsInitialized] = useState<null | boolean>(null);

    const [searchTerm, setSearchTerm] = useState('');

    const currentValue = useMemo(() => {
        if (defaultValue) {
            setValue(defaultValue ?? null);
            return defaultValue;
        }

        return value ?? '';
    }, [defaultValue, value]);

    const validationState = useMemo((): IValidation => {
        // Check for required
        if (required && (value === '' || !value)) {
            return {
                isValid: false,
                message: validationMessage ?? 'Field is required',
            };
        }

        return {
            isValid: true,
            message: '',
        };
    }, [required, value, validationMessage]);

    useEffect(() => {
        if (reportValidation && id) {
            reportValidation(id, validationState.isValid ?? true);
        }
    }, [id, reportValidation, validationState]);

    const validationStyles = useMemo(() => {
        return validationState.isValid || !showValidation
            ? 'border-[rgba(5,15,38,0.24)]'
            : 'border-danger';
    }, [showValidation, validationState.isValid]);

    const borderStyles = useMemo(() => {
        if (!validationState.isValid && showValidation) return 'border-red-600';
        return 'border-neutral-300';
    }, [showValidation, validationState.isValid]);

    const inputWrapperGrid = useMemo(() => {
        if (iconStart) return 'grid grid-cols-[50px,auto]';
        return 'flex flex-row';
    }, [iconStart]);

    const SelectElementDropdownText = useMemo(() => {
        return 'text-formFields';
    }, []);

    const roundedStyles = useMemo(() => {
        if (iconStart) return 'rounded-tr rounded-br';
        return 'rounded';
    }, [iconStart]);

    const showText = useMemo(() => {
        if (preSelected && !isInitialized) {
            const foundOption = options?.find((option) => option.id === preSelected);
            if (foundOption) {
                setValue(preSelected);
                setSelected(labelType === 'id' ? foundOption?.id : foundOption?.name);
            }

            if (onChange) {
                onChange(preSelected);
            }

            setIsInitialized(true);
        }

        // Get the labelOption from options with currentValue as id
        const labelOption = options?.find((option) => option.id === currentValue);

        if (selected) {
            return labelType === 'id' ? labelOption?.id : (labelOption?.name ?? selected);
        } else if (currentValue) {
            return labelType === 'id' ? labelOption?.id : (labelOption?.name ?? currentValue);
        } else {
            return placeholder;
        }
    }, [
        preSelected,
        isInitialized,
        options,
        selected,
        currentValue,
        labelType,
        placeholder,
        onChange,
    ]);

    const textStyling = useMemo(() => {
        return currentValue || selected ? 'text-primary' : 'tracking-wide text-body-light';
    }, [currentValue, selected]);

    const dropDownStyles = useMemo(() => {
        let positionStr = direction === 'down' ? 'top-[60px]' : 'top-[45px]';
        if (direction === 'up') positionStr = 'bottom-11';

        return `absolute ${
            label ? positionStr : 'top-[45px]'
        } z-50 h-max w-full rounded border border-border-light bg-background-light pt-2 transition-all overflow-y-auto max-h-[215px]`;
    }, [label, direction]);

    const optionsFiltered = useMemo(() => {
        if (searchable && searchTerm !== '') {
            return options?.filter((option) => {
                return option.name.toLowerCase().includes(searchTerm.toLowerCase());
            });
        }

        return options;
    }, [options, searchTerm, searchable]);

    const fillMeStyles = useMemo(() => {
        if (showFillMe && !value) {
            return 'bg-danger/10';
        }

        return '';
    }, [showFillMe, value]);

    return (
        <>
            <div
                className="relative flex w-full flex-col gap-0.5"
                data-test-id={`${testId ?? ''}-wrapper`}
                ref={wrapperRef}
            >
                {alignWithFormFields && required && <div className="relative block h-[25px]" />}
                {alignWithFormFields && !required && <div className="relative block h-6" />}
                {label && label !== '' && (
                    <Label required={required} testId={testId} translationKey={labelTranslationKey}>
                        <span className="relative flex flex-row items-center gap-2">
                            {label} {labelTag && labelTag}
                        </span>
                    </Label>
                )}
                <div className={`${inputWrapperGrid} relative z-0 w-full gap-0 pb-0.5`}>
                    {iconStartOpen && (
                        <div className="flex items-center justify-center rounded-tl rounded-bl bg-neutral-300">
                            {iconStartOpen ?? ''}
                        </div>
                    )}
                    {iconStart && (
                        <div className="flex items-center justify-center rounded-tl rounded-bl bg-neutral-300">
                            <span
                                className={`w-6 text-white ${iconSpin ? 'animate-spin' : ''}`}
                                data-test-id={`${testId ?? ''}-iconStart`}
                            >
                                {iconStart}
                            </span>
                        </div>
                    )}
                    <button
                        className={`relative grid h-10 w-full grid-cols-[auto,max-content] border ${
                            disabled ? 'cursor-not-allowed' : ''
                        } ${fillMeStyles} ${roundedStyles} ${validationStyles} ${SelectElementDropdownText} ${borderStyles}`}
                        value={currentValue ?? ''}
                        name={autocomplete}
                        disabled={disabled}
                        id={id ?? undefined}
                        data-test-id={testId ?? ''}
                        key={''}
                        onBlur={() => {
                            if (onBlur) {
                                onBlur(value ?? '');
                            }
                        }}
                        onClick={() => setOpen((prev) => !prev)}
                    >
                        {searchable && (value === '' || !value) && (
                            <input
                                type="text"
                                data-test-id={`${testId ?? ''}-search`}
                                className="h-full w-full border-0 bg-transparent pl-4"
                                placeholder="Suchen ..."
                                value={searchTerm}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                }}
                            />
                        )}

                        {(!searchable || (searchable && value)) && (
                            <div
                                className={`relative inline-block h-10 w-full flex-col gap-1 truncate text-ellipsis whitespace-nowrap pl-4 pt-2 text-start ${textStyling}`}
                                data-test-id={`${testId ?? ''}-text`}
                                {...applyTranslation({
                                    key: selectTranslationKeys,
                                    entity: ETranslationEntity.select,
                                })}
                            >
                                {showText ?? ''}
                            </div>
                        )}

                        {!disabled && (
                            <div className="relative flex flex-row gap-1">
                                {clearable && (
                                    <button
                                        className="transition-all hover:scale-125"
                                        onClick={(event) => {
                                            setValue('');
                                            setSearchTerm('');
                                            setOpen(false);
                                            event.stopPropagation();
                                        }}
                                    >
                                        <XMarkIcon
                                            className={`w-5 text-neutral-500 hover:text-neutral-700 cursor-pointer`}
                                            data-test-id="select-clear-button"
                                        />
                                    </button>
                                )}
                                <div
                                    className="relative flex h-full w-max cursor-pointer flex-col items-end justify-center rounded-tl rounded-br pr-2 transition-all"
                                    data-test-id="select-chevron"
                                >
                                    <figure className="pointer-events-none">
                                        <ChevronUpIcon
                                            className={`w-6 text-primary-dark transition-all ${
                                                open ? 'rotate-0' : 'rotate-180'
                                            }`}
                                            style={{ color: tenantTheme.colors.primary.dark }}
                                        />
                                    </figure>
                                </div>
                            </div>
                        )}
                    </button>
                </div>
                {/* Dropdown */}
                {open && options && (
                    <div
                        className={dropDownStyles}
                        style={{ backgroundColor: tenantTheme.colors.background.light }}
                        data-test-id={`${testId ?? ''}-dropdown`}
                    >
                        {/*  Empty Message */}
                        {options?.length === 0 && (
                            <span
                                className="mb-3 flex w-full px-2 py-2 text-sm italic text-neutral-300"
                                data-test-id={`${testId ?? ''}-empty-entry`}
                            >
                                Leer
                            </span>
                        )}

                        {/* Actual DropDown */}
                        {optionsFiltered &&
                            optionsFiltered?.length > 0 &&
                            optionsFiltered?.map((option) => {
                                return (
                                    // TODO add hover tenantTheme styling
                                    <div
                                        key={`select-option-${option.id}`}
                                        data-test-id={`${testId ?? ''}-${option.id}`}
                                        className={`px-2 py-1 transition-all hover:bg-neutral-200 hover:text-primary ${
                                            option.id === value ? 'text-primary-dark' : 'text-body'
                                        }`}
                                        style={
                                            option.id === value
                                                ? { color: tenantTheme.colors.primary.dark }
                                                : { color: tenantTheme.colors.body.DEFAULT }
                                        }
                                        onClick={() => {
                                            setValue(option.id);
                                            setSearchTerm('');

                                            if (option.id !== selected) {
                                                if (
                                                    option.prefix !== undefined &&
                                                    option.prefix !== null
                                                ) {
                                                    setSelected(option.prefix);
                                                } else setSelected(option.name);
                                            }

                                            if (onChange) onChange(option.id);

                                            setOpen(false);
                                        }}
                                    >
                                        <div className="relative flex flex-row items-center justify-center gap-2">
                                            {option.icon && (
                                                <div className="flex items-center justify-center">
                                                    {option.icon ?? ''}
                                                </div>
                                            )}
                                            {option.prefix && (
                                                <span className="text-sm capitalize text-border">
                                                    {option.prefix}
                                                </span>
                                            )}
                                            <span className="flex h-full w-full cursor-pointer items-center">
                                                {option.name}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                )}
                {/* Validation Message */}
                {!validationState.isValid && showValidation && (
                    <>
                        <ValidationMessage
                            message={validationState.message ?? validationMessage}
                            testId={testId}
                        />
                    </>
                )}
            </div>
        </>
    );
};

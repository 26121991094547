export type TUserAccess = 'admin' | 'qUser' | 'mUser' | 'mRole' | 'mTenant';

export type TStoreAccess = 'admin' | 'mProd' | 'qProd' | 'mCat' | 'qCat' | 'mOrder' | 'qOrder';

export type TAccess = TUserAccess | TStoreAccess;

export interface IAccess {
    id: string;
    name: TAccess;
}

export enum EAccessDescription {
    Admin = 'Admin',
    gAdminArea = 'Can access the /admin area. No further permissions.',
    qUser = 'Can read users and user groups.',
    mUser = 'Can create, update and delete users and user groups.',
    mRole = 'Can create, update and delete roles.',
    qTenant = 'Can read tenants.',
    mTenant = 'Can create, update and delete tenants.',
    mProd = 'Can create, update and delete products.',
    qProd = 'Can read products.',
    mCat = 'Can create, update and delete categories.',
    qCat = 'Can read categories.',
    mOrder = 'Can create, review, update and delete orders.',
    qOrder = 'Can read orders.',
    qContent = 'Can read CMS content.',
    mContent = 'Can create, update, publish delete CMS content.',
    qPrice = 'Can read price tables.',
    mPrice = 'Can create, update and delete price tables.',
    qLocalisation = 'Can read localisations.',
    mLocalisation = 'Can create, update and delete localisations.',
}

export enum EAccessTranslation {
    Admin = 'Admin',
    gAdminArea = 'Admin Area',
    qUser = 'User Read',
    mUser = 'User Write',
    mRole = 'Role Write',
    qTenant = 'Tenant Read',
    mTenant = 'Tenant Write',
    mProd = 'Product Write',
    qProd = 'Product Read',
    mCat = 'Category Write',
    qCat = 'Category Read',
    mOrder = 'Order Write',
    qOrder = 'Order Read',
    qContent = 'Content Read',
    mContent = 'Content Write',
    qPrice = 'Price Read',
    mPrice = 'Price Write',
    qLocalisation = 'Localisation Read',
    mLocalisation = 'Localisation Write',
}

export enum EAccess {
    // Admin = 'admin',
    gAdminArea = 'gAdminArea',
    qUser = 'qUser',
    mUser = 'mUser',
    mRole = 'mRole',
    mTenant = 'mTenant',
    qTenant = 'qTenant',
    mProd = 'mProd',
    qProd = 'qProd',
    mCat = 'mCat',
    qCat = 'qCat',
    mOrder = 'mOrder',
    qOrder = 'qOrder',
    qContent = 'qContent',
    mContent = 'mContent',
    qPrice = 'qPrice',
    mPrice = 'mPrice',
    qLocalisation = 'qLocalisation',
    mLocalisation = 'mLocalisation',
}

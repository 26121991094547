import React, { useMemo } from 'react';
import { useThemeStyles } from '../../theme/TenantTheme/hooks/useThemeStyles';
import { useTranslationApply } from '../../Translations/hooks/useTranslationApply';
import { ETranslationEntity } from '../../Translations/types/translationApply.types';
import { ETranslationType } from '../../Translations/enum/translations.enums';
import { useTranslation } from '../../Translations/hooks/useTranslation';

export interface ITypography {
    variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'description' | 'label' | 'label-tiny';
    className?: string;
    children?: React.ReactNode;
    translationKey?: string;
}

export const Typography: React.FC<ITypography> = (props) => {
    const { variant, children, className, translationKey } = props;

    const { applyTranslation } = useTranslationApply();

    const { applyStyles } = useThemeStyles();

    const { getT } = useTranslation();

    const translation = useMemo(() => {
        if (!translationKey) return undefined;

        return applyTranslation({
            key: translationKey,
            entity: ETranslationEntity.text,
            type: ETranslationType.static,
        });
    }, [applyTranslation, translationKey]);

    return (
        <>
            {variant === 'h1' && (
                <h1
                    className={`font-bold text-xl lg:text-4xl ${className ?? ''}`}
                    style={{
                        ...applyStyles({
                            textColor: 'colors.primary.DEFAULT',
                        }),
                    }}
                    {...translation}
                >
                    {translationKey && getT(translationKey)}
                    {children}
                </h1>
            )}
            {variant === 'h2' && (
                <h2
                    className={`font-bold text-lg lg:text-3xl ${className ?? ''}`}
                    style={{
                        ...applyStyles({
                            textColor: 'colors.primary.DEFAULT',
                        }),
                    }}
                    {...translation}
                >
                    {translationKey && getT(translationKey)}
                    {children}
                </h2>
            )}
            {variant === 'h3' && (
                <h3
                    className={`font-bold text-2xl ${className ?? ''}`}
                    style={{
                        ...applyStyles({
                            textColor: 'colors.body.DEFAULT',
                        }),
                    }}
                    {...translation}
                >
                    {translationKey && getT(translationKey)}
                    {children}
                </h3>
            )}
            {variant === 'h4' && (
                <h4
                    className={`text-xl font-semibold ${className ?? ''}`}
                    style={{
                        ...applyStyles({
                            textColor: 'colors.body.DEFAULT',
                        }),
                    }}
                    {...translation}
                >
                    {translationKey && getT(translationKey)}
                    {children}
                </h4>
            )}
            {variant === 'h5' && (
                <h5
                    className={`text-lg font-medium ${className ?? ''}`}
                    style={{
                        ...applyStyles({
                            textColor: 'colors.body.DEFAULT',
                        }),
                    }}
                    {...translation}
                >
                    {translationKey && getT(translationKey)}
                    {children}
                </h5>
            )}
            {variant === 'h6' && (
                <h6
                    className={`text-sm font-medium ${className ?? ''}`}
                    style={{
                        ...applyStyles({
                            textColor: 'colors.body.DEFAULT',
                        }),
                    }}
                    {...translation}
                >
                    {translationKey && getT(translationKey)}
                    {children}
                </h6>
            )}
            {variant === 'description' && (
                <p
                    className={`text-xs font-normal lg:text-sm ${className ?? ''}`}
                    style={{
                        ...applyStyles({
                            textColor: 'colors.body.light',
                        }),
                    }}
                    {...translation}
                >
                    {translationKey && getT(translationKey)}
                    {children}
                </p>
            )}
            {variant === 'label' && (
                <span
                    className={`lg:text-md text-xs font-medium ${className ?? ''}`}
                    style={{
                        ...applyStyles({
                            textColor: 'colors.labels.DEFAULT',
                        }),
                    }}
                    {...translation}
                >
                    {translationKey && getT(translationKey)}
                    {children}
                </span>
            )}
            {variant === 'label-tiny' && (
                <span
                    className={`text-[9px] ${className ?? ''}`}
                    style={{
                        ...applyStyles({
                            textColor: 'colors.body.light',
                        }),
                    }}
                    {...translation}
                >
                    {translationKey && getT(translationKey)}
                    {children}
                </span>
            )}
        </>
    );
};

import React, { useMemo } from 'react';
import { IPopoverMenu, PopoverMenu } from '../../elements/popOverMenu/PopoverMenu';
import { IconButton } from '../button/IconButton';
import { ChevronDownIcon } from '@heroicons/react/16/solid';
import { IPopOverMenuItem } from '../../elements/popOverMenu/interfaces/popoverMenu.interfaces';
import { FlagSymbol } from '../../elements/FlagSymbol/FlagSymbol';
import { ILocale } from '../../../../interfaces/localisation/locale.interfaces';
import { useLanguageName } from '../../hooks/localisation/useLanguageName';

export interface ILanguageSelector {
    isAdminPanel?: boolean;
    openToVertical?: IPopoverMenu['openToVertical'];
    openToSide?: IPopoverMenu['openToSide'];
    options: ILocale[];
    selected: ILocale;
    onSelect: (locale: ILocale) => void;
    currentLanguage?: ILocale;
    flagOnly?: boolean;
}

export const LanguageSelector: React.FC<ILanguageSelector> = (props) => {
    const {
        openToVertical,
        onSelect,
        selected,
        options,
        openToSide,
        currentLanguage = { languageCode: 'de-DE', default: false },
        flagOnly = false,
    } = props;

    const { getLanguageName } = useLanguageName(currentLanguage.languageCode);

    const items: IPopOverMenuItem[] = useMemo(() => {
        return options.map((locale) => {
            return {
                id: locale.languageCode,
                icon: <FlagSymbol countryCode={locale.languageCode} />,
                label: getLanguageName(locale.languageCode.split('-')[0] as string) as string,
                onClick: () => {
                    onSelect(locale);
                },
            };
        });
    }, [getLanguageName, onSelect, options]);

    return (
        <PopoverMenu
            items={items}
            openToSide={openToSide ?? 'right'}
            testId="popovertest"
            openToVertical={openToVertical}
        >
            <div className="relative flex w-full flex-row items-center gap-2">
                <div className="h-5">
                    <FlagSymbol countryCode={selected.languageCode} />
                </div>
                {!flagOnly && (
                    <>
                        <span className="flex font-medium">
                            {getLanguageName(selected.languageCode.split('-')[0] as string)}
                        </span>
                        <IconButton
                            wrapperCss="pointer-events-none"
                            preventDefault={true}
                            onClick={() => {}}
                            icon={
                                <ChevronDownIcon
                                    className={`h-6 ${openToVertical === 'up' ? 'rotate-180' : ''}`}
                                />
                            }
                        />
                    </>
                )}
            </div>
        </PopoverMenu>
    );
};

import React, { useMemo } from 'react';
import { UserContextToggle } from './UserContextToggle';
import { useAdminControlsState } from '../../adminControls/state/adminControls.state';

interface IUserContextToggleCMSControls {}

export const UserContextToggleCMSControls: React.FC<IUserContextToggleCMSControls> = () => {
    const { setShowFAB, showFAB, reset } = useAdminControlsState();

    // ⌘+k pr CTRL+k
    const placeholderSuffix = useMemo(() => {
        const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
        return isMac ? '⌘+L' : 'CTRL+L';
    }, []);

    return (
        <UserContextToggle
            labelSmall={'Admin Controls'}
            label={`Admin Controls ${placeholderSuffix}`}
            set={(checked) => {
                const newState = checked;
                setShowFAB(newState);

                if (!newState) {
                    // When turning off
                    reset();
                } else {
                    // When turning on, restore previous state
                    const adminControlsState = useAdminControlsState.getState();
                    adminControlsState.restoreControlsState();
                }
            }}
            checked={showFAB ?? false}
        />
    );
};

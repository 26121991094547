import { useUserSetFieldValueMutation } from '../../../admin/areas/userFields/hooks/useUserSetFieldValueMutation';
import { useCallback } from 'react';

export const useUpdateUserLanguage = () => {
    const { mutateAsync: setFieldValue, isPending } = useUserSetFieldValueMutation();

    const updateUserLanguage = useCallback(
        async (languageCode: string) => {
            const regEx = /^[a-z]{2}-[A-Z]{2}$/;
            if (!regEx.test(languageCode)) {
                console.error(
                    `Invalid language code: ${languageCode ?? ''} in updateUserLanguage()`
                );
                return null;
            }

            await setFieldValue({ fieldName: 'generalLanguage', fieldValue: languageCode });
        },
        [setFieldValue]
    );

    return { updateUserLanguage, isPending };
};

import { IUser } from '../../../interfaces/IUser';
import { atom, DefaultValue, selector } from 'recoil';
import { IGeneralInfo } from '../../../interfaces/profile/IGeneralInfo';
import { IBillingInfo } from '../../../interfaces/profile/IBillingInfo';
import { IClientSpecificData } from '../../../interfaces/profile/IClientSpecificData';

interface ICurrentUser {
    user: IUser | null;
    currentGeneralInfo: IGeneralInfo;
    currentBillingInfo: IBillingInfo;
    currentClientSpecificData: IClientSpecificData;
}

export const currentUserState = atom<ICurrentUser>({
    key: `current-user-state`,
    default: {
        user: null,
        currentGeneralInfo: {} as IGeneralInfo,
        currentBillingInfo: {} as IBillingInfo,
        currentClientSpecificData: {} as IClientSpecificData,
    },
});

export const loggedInUserSelector = selector<IUser | null>({
    key: 'loggedInUser',
    get: ({ get }) => get(currentUserState).user,
    set: ({ set }, newValue) =>
        set(
            currentUserState,
            newValue instanceof DefaultValue
                ? newValue
                : (currVal) => {
                      return { ...currVal, user: newValue };
                  }
        ),
});

export const isAdminUserSelector = selector<boolean>({
    key: 'isAdminUser',
    get: ({ get }): boolean => get(currentUserState).user?.admin ?? false,
    set: ({ set }, newValue) =>
        set(
            currentUserState,
            newValue instanceof DefaultValue
                ? newValue
                : (currVal) => {
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      return { ...currVal, user: { ...currVal.user!, admin: newValue } };
                  }
        ),
});

export const emailSelector = selector<string>({
    key: 'emailSelector',
    get: ({ get }): string => get(currentUserState).user?.email ?? '',
});

import { useCallback } from 'react';

export const useLanguageName = (currentLanguageCode: string) => {
    const currentLanguage = currentLanguageCode.split('-')[0] ?? 'de';

    const languageNames = useCallback(() => {
        return new Intl.DisplayNames([currentLanguage], { type: 'language' });
    }, [currentLanguage]);

    const regionNames = useCallback(() => {
        return new Intl.DisplayNames([currentLanguage], { type: 'region' });
    }, [currentLanguage]);

    const getLanguageName = useCallback(
        (languageCode: string) => {
            return languageNames().of(languageCode ?? 'DE');
        },
        [languageNames]
    );

    const getRegionName = useCallback(
        (regionCode: string | undefined) => {
            if (!regionCode) {
                regionCode = 'DE';
            } else {
                // Handle locale format (e.g., de-DE)
                if (regionCode.includes('-')) {
                    // Extract the country part from locale (the part after the dash)
                    regionCode = regionCode.split('-')[1];
                }

                // Normalize the code to ensure it's a valid region code (always uppercase)
                // Region codes in Intl.DisplayNames must be 2-letter uppercase codes
                if (regionCode?.length === 2) {
                    regionCode = regionCode.toUpperCase();
                } else {
                    // Default to 'DE' if the format is invalid
                    regionCode = 'DE';
                }
            }
            return regionNames().of(regionCode);
        },
        [regionNames]
    );

    return { getLanguageName, getRegionName };
};
